import React from 'react';
import { DateTime } from 'luxon';
import Money from '../Money';
import DrawingLocation from "../DrawingLocation";

import * as UI from "./ui";

const scrollWithOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const headerHeight = document.getElementById('header').offsetHeight;
  const yOffset = -1 * (headerHeight + 10);
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const Overview = ({ sweepstakes }) => {
  const endsAt = sweepstakes.endsAt && DateTime.fromISO(sweepstakes.endsAt, { zone: sweepstakes.timezone });
  const winningTicketDrawnAt = sweepstakes.winningTicketDrawnAt && DateTime.fromISO(sweepstakes.winningTicketDrawnAt, { zone: sweepstakes.timezone });
  const cashAlternative = sweepstakes.prize.cashAlternative;

  return (
    <React.Fragment>
      <UI.AmoeLink to="/rules#how-to-enter" scroll={scrollWithOffset}>
        No contribution necessary to enter, see details here
      </UI.AmoeLink>
      { cashAlternative > 0 && (
        <UI.OverviewLayout>
          <UI.OverviewHeading>Grand Prize Cash Option</UI.OverviewHeading>
          <UI.OverviewItem>
            <UI.CashAlternative>
              <Money cents={cashAlternative} />
            </UI.CashAlternative>
          </UI.OverviewItem>
        </UI.OverviewLayout>
      )}
      <UI.OverviewLayout>
        <UI.OverviewHeading>Sweepstakes Details</UI.OverviewHeading>
        <UI.OverviewItem>
          <UI.Label>Close Date</UI.Label>
          <UI.Value>{endsAt ? endsAt.toLocaleString(DateTime.DATE_MED) : "TBD"}</UI.Value>
        </UI.OverviewItem>
        <UI.OverviewItem>
          <UI.Label>Close Time</UI.Label>
          <UI.Value>{endsAt ? endsAt.toFormat("t ZZZZ") : "TBD"}</UI.Value>
        </UI.OverviewItem>
        <UI.OverviewItem>
          <UI.Label>Drawing Date</UI.Label>
          <UI.Value>{winningTicketDrawnAt ? winningTicketDrawnAt.toLocaleString(DateTime.DATE_MED) : "TBD"}</UI.Value>
        </UI.OverviewItem>
        <UI.OverviewItem>
          <UI.Label>Drawing Time</UI.Label>
          <UI.Value>{winningTicketDrawnAt ? winningTicketDrawnAt.toFormat("t ZZZZ") : "TBD"}</UI.Value>
        </UI.OverviewItem>
        <UI.OverviewItem>
          <UI.Label>Drawing Location</UI.Label>
          <UI.Value>
            <DrawingLocation location={sweepstakes.location} />
          </UI.Value>
        </UI.OverviewItem>
      </UI.OverviewLayout>
    </React.Fragment>
  );
}

export default Overview;
