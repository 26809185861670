import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Layout from "../../Layout";
import Share from "../../Share";
import Money from "../../Money";
import { ToolContext } from "../ToolProvider";
import DonationCard from "../DonationCard";
import * as UI from "./ui";

const ReceiptStep = ({ nonprofit, sweepstakes }) => {
  const { donationLevel, donor, promoCode } = useContext(ToolContext);
  const { activeThankYouCampaign } = sweepstakes;

  return (
    <React.Fragment>
      <Layout.FullWidth fold id="receipt">
        <Layout.FullWidthColumn>
          <UI.Heading>Thank you for your donation</UI.Heading>
        </Layout.FullWidthColumn>
        <Layout.FixedColumn span={5} breakpoint="belowTablet">
          <DonationCard donationLevel={donationLevel} promoCode={promoCode} donor={donor} />
        </Layout.FixedColumn>
        <Layout.FlexibleColumn span={[7,15]} breakpoint="belowTablet" spaced>
          <UI.ThankYou>Check your email for your entries.</UI.ThankYou>
          <UI.Message>
            We sincerely appreciate your support of {nonprofit.name}, EIN {nonprofit.ein}.
            A donation receipt including your official sweepstakes entries has been emailed to you.
            Please print your email receipt and keep it in a safe place.
            Your sweepstakes entries have been placed in the official sweepstakes entry pool.
          </UI.Message>

          <UI.ShareCTA>Please share your donation with your friends and help support our cause.</UI.ShareCTA>
          <Share receipt />
          {activeThankYouCampaign && (
            <Link to={`/?promo=${activeThankYouCampaign.promoCode.name}`}>
            <UI.ThankYouCampaign />
            </Link>
          )}
          <UI.HiddenAmount id="receipt-amount">
            <Money cents={donationLevel.price} format="0.00" />
          </UI.HiddenAmount>
        </Layout.FlexibleColumn>
      </Layout.FullWidth>
    </React.Fragment>
  );
}

export default ReceiptStep;
