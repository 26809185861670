import React from 'react';

import Money from "../Money"
import * as UI from "./ui";

const DonationLevel = ({ donationLevel, promoCode, onClick, selected, guarded }) => {
  const { rewardTickets, price } = donationLevel;

  const ticketString = rewardTickets === 1 ? '1 Entry' : `${rewardTickets} Entries`;

  const handleClick = onClick && (() => {
    if(!guarded) {
      onClick(donationLevel);
    }
  })

  const bonusPercent = (promoCode && promoCode.bonusPercent) || 0;
  const promoTickets = Math.ceil((rewardTickets * bonusPercent) / 100)
  const promoTicketString = promoTickets && promoTickets === 1 ? '+1 Promo' : `+${promoTickets} Promo`;

  return (
    <UI.Layout onClick={handleClick} selected={selected} guarded={guarded} level={donationLevel.level}>
      <UI.Price>
        <Money cents={price} />
      </UI.Price>
      <UI.Reward>{ticketString}</UI.Reward>
      <UI.Promo applied={promoTickets}>{promoTicketString}</UI.Promo>
    </UI.Layout>
  );
}

export default DonationLevel;
