import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Layout from "../../Layout";
import * as UI from "./ui";
import { trackSuccess } from "./utils";
import { ToolContext } from "../ToolProvider";
import DonationCard from "../DonationCard";
import ClosedContent from "../ClosedContent";

const STRIPE_CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px',
    }
  }
}

const PaymentStep = ({ donationLevels, onComplete, sweepstakes }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { donationLevel, donor, promoCode, paymentIntent, expired } = useContext(ToolContext);
  const [ processing, setProcessing ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    setProcessing(true);

    const options = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${donor.lastName}, ${donor.firstName}`,
          address: {
            line1: donor.addressLine1,
            line2: donor.addressLine2,
            city: donor.city,
            state: donor.state,
            postal_code: donor.zipCode,
            country: donor.country,
          },
          email: donor.email,
          phone: donor.phoneNumber,
        },
      }
    };

    stripe.confirmCardPayment(paymentIntent.clientSecret, options).then(result => {
      if(result.error) {
        setErrorMessage(result.error.message);
        setProcessing(false);
      } else {
        trackSuccess({
          paymentIntent: result.paymentIntent,
          donationLevel,
          promoCode,
          sweepstakes,
          donor,
        });
        onComplete();
      }
    });
  };

  if(expired && !processing) {
    return <ClosedContent sweepstakes={sweepstakes} />
  }

  return (
    <React.Fragment>
      <Layout.FullWidth>
        <Layout.FlexibleColumn span={[1,20]} breakpoint="belowLaptop">
          <UI.SubstepHeading>Review Your Donation</UI.SubstepHeading>
        </Layout.FlexibleColumn>
      </Layout.FullWidth>
      <Layout.FullWidth fold>
        <Layout.FixedColumn span={5} breakpoint="belowLaptop">
          <DonationCard donationLevel={donationLevel} promoCode={promoCode} donor={donor} />
        </Layout.FixedColumn>
        <Layout.FlexibleColumn span={[7,15]} breakpoint="belowLaptop">
          { errorMessage && <UI.ErrorMessage>{errorMessage}</UI.ErrorMessage> }
          <UI.Form onSubmit={onSubmit}>
            <UI.StripeElementLayout>
              <UI.StripeElement>
                <CardElement options={STRIPE_CARD_ELEMENT_OPTIONS} />
              </UI.StripeElement>
            </UI.StripeElementLayout>
            <UI.ActionButton value={processing ? "Processing..." : "Complete Donation"} disabled={processing} />
          </UI.Form>
        </Layout.FlexibleColumn>
      </Layout.FullWidth>
    </React.Fragment>
  )
};

export default PaymentStep;
