import { gql } from "@apollo/client";

export const setupPaymentIntentMutation = gql`
  mutation SetupPaymentIntent($attributes: PaymentIntentInput!) {
    setupPaymentIntent(attributes: $attributes) {
      error {
        code
        message
      }
      paymentIntent {
        id
        clientSecret
      }
    }
  }
`;

