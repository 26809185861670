import React from "react";

import { pickTextColor } from "../../lib/color";
import Layout from "../Layout";
import * as UI from "./ui";

const AnnouncementBar = ({ sweepstakes }) => {
  if(!sweepstakes.announcement && !sweepstakes.activeFlashContest) {
    return null;
  }

  const announcement = sweepstakes.activeFlashContest?.announcement || sweepstakes.announcement;
  const backgroundColor = sweepstakes.activeFlashContest ? "#ff5822" : sweepstakes.nonprofit.profile.color;
  const color = sweepstakes.activeFlashContest ? "#ffffff" : pickTextColor({ background: backgroundColor, light: '#fff', dark: '#000' });

  return (
    <Layout.FullWidth fold>
      <Layout.FullWidthColumn spaced>
        <UI.Background style={{ backgroundColor, color }}>
          {announcement}
        </UI.Background>
      </Layout.FullWidthColumn>
    </Layout.FullWidth>
  );
}

export default AnnouncementBar;
