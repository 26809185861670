import styled from "styled-components";

export const Heading = styled.div`
  font-size: 1.5em;
  margin-bottom: 10px;
  padding: 10px 0;

  @media screen and (min-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
    padding: 20px 0;
  }
`;

export const ThankYou = styled.p`
  font-size: 1.125em;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const Message = styled.p`
  color: #555555;
  margin-bottom: 20px;
  line-height: 1.25em;
`;

export const ShareCTA = styled.p`
  color: #555555;
  margin-bottom: 20px;
`;

export const ThankYouCampaign = styled.img.attrs({
  src: "https://res.cloudinary.com/tapkat/image/upload/v1724105288/2XTHANKS_fybeqb.png",
})`
  width: 100%;
  margin-top: 10px;

  @media screen and (min-width: 650px) and (max-width: 767px) {
    width: 80%;
  }
  @media screen and (min-width: 1024px) {
    width: 80%;
  }
`;

export const HiddenAmount = styled.div`
  display: none;
`;
